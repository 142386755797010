export const appConstants = {

    // baseUrl: "http://1.6.98.141:9053/api/v1",
    // imageUrl: "http://1.6.98.141:9053",

    //baseUrl: "http://192.168.3.175:9055/api/v1",
    //imageUrl: "http://192.168.3.175:9055/",

    baseUrl: "https://prod.buzzinc.co/api/v1",
    imageUrl: "https://prod.buzzinc.co/",

    // baseUrl: "http://3.143.249.50:3000/api/v1",
    // imageUrl: "http://3.143.249.50:3000/",

    appName: "Buzz",
    login: "Admin Login",
    emailAddress: "Email Address",
    logoutWarning: "Are you sure, you want to logout?",
    logout: "Log out",
    yes: "Yes",
    no: "No",
    email: "Email",
    dashboard: "Dashboard",
    totalCustomers: "Total Customers",
    totalServiceProvider: "Total Service Provider",
    stats: "Stats",
    activeAccounts: "Active accounts",
    top5Categories: "Top 5 Categories",
    customer: "Customer",
    serviceProvider: "Service Provider",
    active: "Active",
    signout: "Signout",
    totalActiveAccounts: "Total active accounts",
    viewAllCategory: "View all category",
    forgotPasswordText: "Remember & input your Email address below",
    continue: "Continue",
    authCopyRightText: "Buzz Plantforms INC. All Rights Reserved.",
    forgotPassword: "Forgot Password",
    otpVerification: "OTP Verification",
    otpVerificationText: "We just sent you a verification code to your email",
    verify: "Verify",
    resendOtpText: "Resend Code in 00:",
    createNewPassword: "Create new password",
    createPasswordText: "Enter your new password here",
    reset: "Reset",
    confirmNewPassword: "Confirm new password",
    customers: "Customers",
    exportCsv: "Export CSV",
    importCsv: "Import CSV",
    name: "Name",
    phoneNumber: "Phone Number",
    emailId: "E-mail ID",
    emailid: "Email ID",
    status: "Status",
    actions: "Actions",
    action: "Action",
    previous: "Previous",
    next: "Next",
    serviceProviders: "Service providers",
    add: "Add",
    serviceCategory: "Service category",
    subCategory: "Sub Category",
    subcategory: "Sub category",
    manage: "Manage",
    serviceCategories: "Service categories",
    trendingServices: "Trending services",
    serviceName: "Service name",
    delete: "Delete",
    deleteCustomerWarning: "Are you sure, you want to delete this customer?",
    customerPersonalDetails: "Customer personal details",
    location: "Location",
    serviceUserInLastSixMonths: "Service used in last six months",
    serviceGoingToUseInNextSixMonths: "Service going to use in Next six months",
    activities: "Activities",
    totalConnections: "Total connections",
    providerAdded: "Provider added",
    reviewAdded: "Review added",
    providerPersonalDetails: "Provider Personal Details",
    totalReviews: "Total Reviews",
    deleteProviderWarning: "Are you sure, you want to delete this provider?",
    serviceOffered: "Service Offered ",
    totalRating: "Total Rating",
    enterLocation: "Enter Location",
    age: "Age",
    phoneNo: "Phone no",
    number: "Number",
    submit: "Submit",
    pickCategory: "Pick Category",
    pickSubCategory: "Pick Sub-category",
    reviews: "Reviews",
    provider: "Provider",
    service: "Service",
    services: "Services",
    rating: "Rating",
    category: "Category",
    reviewRating: "Review Rating",
    reviewComments: "Review Comments",
    deleteReviewWarning: "Are you sure, you want to delete this review?",
    cmsPages: "CMS Pages",
    title: "Title",
    url: "URL",
    order: "Order",
    id: "ID",
    deleteCMSWarning: "Are you sure, you want to delete this CMS?",
    report: "Report",
    feedback: "Feedback",
    request: "Request",
    topic: "Topic",
    date: "Date",
    total: "Total",
    notification: "Notification",
    contentUpdates: "Content Updates",
    activityBasedUpdated: "Activity-Based Updates",
    broadcastNotification: "Broadcast Notification",
    heading: "Heading",
    lastUpdated: "Last Updated",
    deleteNotificationWarning: "Are you sure, you want to delete this notification?",
    content: "Content",
    time: "Time",
    deleteCategoryWarning: "Are you sure, you want to delete this category?",
    writeHeading: "Write heading",
    writeContent: "Write content",
    zipcode: "Zip Code",
    cancel: "Cancel",
    sNo: "S.No",
    reviewDetails: "Review details",
    offlineMessage: "Your internet connection appears to be offline. Please try again.",
    messageStatus500: "Something went wrong.",
    loggedIn: "Logged in successfully.",
    emailRequired: "Please enter email address.",
    invalidEmail: "Please enter valid email address.",
    passwordRequired: "Please enter password.",
    forgotPasswordLinkSent: "OTP has been sent to your registered email address.",
    otpVerified: "OTP has been verified successfully.",
    newPasswordRequired: "Please enter new password.",
    confirmNewPasswordRequired: "Please enter confirm new password.",
    confirmNewPasswordMustBeSame: "Confirm new password must match with new password.",
    passwordValidation: "Password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.",
    minPasswordValidation: "Password must have at least 8 characters.",
    passwordChanged: "Password has been updated successfully.",
    loggedOut: "Logged out successfully.",
    nameRequired: "Please enter name.",
    invalidName: "Please enter valid name.",
    ageRequired: "Please enter age.",
    companyRequired: "Please enter company.",
    nameMinValidation: "Name must contain atleast 3 characters.",
    phoneMinValidation: "Phone number must contain atleast 8 characters.",
    locationRequired: "Please enter location.",
    phoneRequired: "Please enter phone number.",
    categoryRequired: "Please select category.",
    subCategoryRequired: "Please select sub category.",
    providerAddedMessage: "Provider has been added successfully.",
    providerEditMessage: "Provider details has been updated successfully.",
    deleteCategoryMessage: "Category has been deleted successfully.",
    setAsTrending: "Service has been added to trending.",
    removeAsTrending: "Service has been removed from trending.",
    categoryNameRequired: "Please enter category name.",
    invalidCategoryName: "Please enter valid category name.",
    categoryNameMinValidation: "Category name must contain atleast 3 characters.",
    subCategoryNameRequired: "Please enter sub category name.",
    invalidSubCategoryName: "Please enter valid sub category name.",
    subCategoryNameMinValidation: "Sub category name must contain atleast 3 characters.",
    categoryAddMessage: "Category has been added successfully.",
    categoryUpdateMessage: "Category has been updated successfully.",
    serviceAddedMessage: "Sub category has been added successfully.",
    serviceDeleteMessage: "Sub category has been deleted successfully.",
    serviceEditMessage: "Sub category has been updated successfully.",
    noDataFound: "No data found.",
    feedbackDetails: "Feedback details",
    uploadIcon: "Upload Icon",
    ratingDeleted: "Rating has been deleted successfully.",
    company: "company",

}

export const apiConstants = {

    // Search bar
    handleSearchOnChange: "handleSearchOnChange",

    // authentication
    apiLoginInitiate: "apiLoginInitiate",
    apiLoginSuccess: "apiLoginSuccess",

    apiLogoutInitiate: "apiLogoutInitiate",
    apiLogoutSuccess: "apiLogoutSuccess",

    apiForgotPasswordInitiate: "apiForgotPasswordInitiate",
    apiForgotPasswordSuccess: "apiForgotPasswordSuccess",

    apiVerifyOtpInitiate: "apiVerifyOtpInitiate",
    apiVerifyOtpSuccess: "apiVerifyOtpSuccess",

    apiResetPasswordInitiate: "apiResetPasswordInitiate",
    apiResetPasswordSuccess: "apiResetPasswordSuccess",

    apiDashboardInitiate: "apiDashboardInitiate",
    apiDashboardSuccess: "apiDashboardSuccess",

    apiAuthFailed: "apiAuthFailed",

    // User management
    apiCustomerListInitiate: "apiCustomerListInitiate",
    apiCustomerListSuccess: "apiCustomerListSuccess",

    apiCustomerListForCsvInitiate: "apiCustomerListForCsvInitiate",
    apiCustomerListForCsvSuccess: "apiCustomerListForCsvSuccess",
    apiCustomerListForCsvRemove: "apiCustomerListForCsvRemove",

    apiCustomerBlockInitiate: "apiCustomerBlockInitiate",
    apiCustomerBlockSuccess: "apiCustomerBlockSuccess",

    apiCustomerDeleteInitiate: "apiCustomerDeleteInitiate",
    apiCustomerDeleteSuccess: "apiCustomerDeleteSuccess",

    apiAddProviderInitiate: "apiAddProviderInitiate",
    apiAddProviderSuccess: "apiAddProviderSuccess",

    apiEditProviderInitiate: "apiEditProviderInitiate",
    apiEditProviderSuccess: "apiEditProviderSuccess",

    apiVerifyProviderInitiate: "apiVerifyProviderInitiate",
    apiVerifyProviderSuccess: "apiVerifyProviderSuccess",

    apiImportProviderInitiate: "apiImportProviderInitiate",
    apiImportProviderSuccess: "apiImportProviderSuccess",

    apiCustomerManagementFailed: "apiCustomerManagementFailed",

    // Category Management
    apiCatgoryListInitiate: "apiCatgoryListInitiate",
    apiCatgoryListSuccess: "apiCatgoryListSuccess",

    apiSubCatgoryListInitiate: "apiSubCatgoryListInitiate",
    apiSubCatgoryListSuccess: "apiSubCatgoryListSuccess",

    apiDeleteCatgoryInitiate: "apiDeleteCatgoryInitiate",
    apiDeleteCatgorySuccess: "apiDeleteCatgorySuccess",

    apiAddCatgoryInitiate: "apiAddCatgoryInitiate",
    apiAddCatgorySuccess: "apiAddCatgorySuccess",

    apiEditCatgoryInitiate: "apiEditCatgoryInitiate",
    apiEditCatgorySuccess: "apiEditCatgorySuccess",

    apiCategoryManagementFailed: "apiCategoryManagementFailed",

    // Service Management
    apiServiceListInitiate: "apiServiceListInitiate",
    apiServiceListSuccess: "apiServiceListSuccess",

    apiChangeTrendingServiceStatusInitiate: "apiChangeTrendingServiceStatusInitiate",
    apiChangeTrendingServiceStatusSuccess: "apiChangeTrendingServiceStatusSuccess",

    apiAddServiceInitiate: "apiAddServiceInitiate",
    apiAddServiceSuccess: "apiAddServiceSuccess",

    apiDeleteServiceInitiate: "apiDeleteServiceInitiate",
    apiDeleteServiceSuccess: "apiDeleteServiceSuccess",

    apiEditServiceInitiate: "apiEditServiceInitiate",
    apiEditServiceSuccess: "apiEditServiceSuccess",

    apiServiceManagementFailed: "apiServiceManagementFailed",

    // Report Management
    apiReportListInitiate: "apiReportListInitiate",
    apiReportListSuccess: "apiReportListSuccess",

    apiReportManagementFailed: "apiReportManagementFailed",

    // Review Management
    apiReviewListInitiate: "apiReviewListInitiate",
    apiReviewListSuccess: "apiReviewListSuccess",

    apiReviewDeleteInitiate: "apiReviewDeleteInitiate",
    apiReviewDeleteSuccess: "apiReviewDeleteSuccess",

    apiReviewBlockInitiate: "apiReviewBlockInitiate",
    apiReviewBlockSuccess: "apiReviewBlockSuccess",

    apiReviewManagementFailed: "apiReviewManagementFailed",

}
