import { appConstants } from "../../utils/appConstants";
import images from "../../utils/images";
import Breadcrumbs from "../common/Breadcrumb";
import { Table, Switch, Pagination, Select, Checkbox, Modal, Rate } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchAction, customerListForCsvAction, customerListAction, customerListForCsvRemoveAction, customerBlockAction, customerDeleteAction, categoryListAction, addProviderAction, editProviderAction, verifyProviderAction, importCsvFromProviderAction, serviceListAction } from "../../redux/action";
import { convertArrayToCSV } from 'convert-array-to-csv'
import { useFormik } from "formik";
import { addProviderSchema } from "../../utils/validation";
import { capitalizeFirstLetter } from "../../utils/helper";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const ServiceProviderManagement = () => {

    const dispatch = useDispatch()

    const [modalVisible, setModalVisible] = useState(false)
    const [showViewModal, setViewModal] = useState(false)
    const [showAddModal, setAddModal] = useState(false)
    const [offsetData, setOffsetData] = useState({ offset: 0, limit: 10 })
    const [currentPage, setCurrentPage] = useState(1)
    const [currentUser, setCurrentUser] = useState(null)
    const [profileImage, setProfileImage] = useState(null)
    const [locationInputValue, setLocationInputValue] = useState("")
    // const [subCategoryList, setSubCategoryList] = useState([])
    const [userAction, setUserAction] = useState("add")
    const [initialValues, setInitialValues] = useState({ name: "", company: "", email: "", phone: "", location: [], subCategory: [], zipcode: "" })
    const showModal = () => setModalVisible(true)
    const hideModal = () => setModalVisible(false)

    const { loading, userListData, userListForCsv } = useSelector(x => x.customerManagement)
    const { serviceListData } = useSelector(x => x.serviceManagement)
    const { categoryListForDropdown } = useSelector(x => x.categoryManagement)
    const { searchValue } = useSelector(x => x.authentication)

    let prevOffset = ((currentPage - 1) * offsetData.limit) - offsetData.limit
    let nextOffset = ((currentPage + 1) * offsetData.limit) - offsetData.limit


    const handleAutoCompleteChange = address => {

        setLocationInputValue(address.startsWith(' ') ? address.trimLeft() : address)
    };


    const formik = useFormik({

        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {

            const formData = new FormData()

            formData.append("type", "provider")
            formData.append("name", values.name)
            formData.append("company", values.company)
            formData.append("email", values.email)
            formData.append("phone", values.phone)
            formData.append("location", JSON.stringify(values.location))
            // formData.append("category", JSON.stringify(values.category?.map(i => i.value)))
            formData.append("subCategory", JSON.stringify(values.subCategory?.map(i => i.value)))

            if (profileImage) formData.append('image', profileImage)
            // if (initialValues.zipcode) formData.append('zipcode', initialValues.zipcode)

            if (userAction === "add") dispatch(addProviderAction({ payload: formData, list: { ...offsetData, search: searchValue, type: "provider" } }))
            else {

                formData.append('provider', currentUser._id)
                dispatch(editProviderAction({ payload: formData, list: { ...offsetData, search: searchValue, type: "provider" } }))
            }

            setAddModal(false)
            formik.resetForm()
        },
        validationSchema: addProviderSchema
    })

    const handleAutoCompleteSelect = async (selectedAddress) => {

        try {

            // setInitialValues({ ...formik.values, location: [...formik.values.location, selectedAddress.startsWith(' ') ? selectedAddress.trimLeft() : selectedAddress] })

            const results = await geocodeByAddress(selectedAddress);
            const latLng = await getLatLng(results[0]);

            // Extracting the zip code from the address components
            const zipCodeComponent = results[0].address_components.find((component) => component.types.includes('postal_code'));
            const city = results[0].address_components.find((component) => component.types.includes('locality'))?.long_name
            setLocationInputValue("")
            city && setInitialValues({ ...formik.values, location: [...formik.values.location, { latitude: latLng.lat, longitude: latLng.lng, city, zipcode: zipCodeComponent ? zipCodeComponent.long_name : null }] })
        }
        catch (error) { console.error('Error fetching location:', error); }
    };

    const columns = [
        {
            title: "",
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            width: "120px",
            align: "center",
            render: (value, item, index) => <div><Checkbox className="check-box" checked={item?.isVerified} onChange={(e) => dispatch(verifyProviderAction({ userId: item?._id, isVerified: e.target.checked, type: "provider" }))} /></div>,
        },
        {
            title: "",
            dataIndex: "srno",
            key: "srno",
            sorter: false,
            width: "120px",
            align: "center",
            render: (value, item, index) => <div><Switch checked={item.isBlock} onChange={(e) => dispatch(customerBlockAction({ userId: item?._id, type: "provider" }))} /></div>,
        },
        {
            title: appConstants?.name,
            dataIndex: "name",
            key: "name",
            sorter: false,
            render: (title, data, index) => (<>{capitalizeFirstLetter(data?.name) || "N/A"}</>)
        },
        {
            title: appConstants?.phoneNumber,
            dataIndex: "phone",
            key: "phone",
            sorter: false,
            render: (title, data, index) => (<>{data.phone || "N/A"}</>)
        },
        {
            title: appConstants?.emailId,
            dataIndex: "email",
            key: "email",
            sorter: false,
            render: (title, data, index) => (<>{data.email || "N/A"}</>)
        },
        // {
        //     title: appConstants?.category,
        //     dataIndex: "serviceCategory",
        //     key: "serviceCategory",
        //     sorter: false,
        //     render: (title, data, index) => (<>{(data?.providerCategory ? data?.providerCategory[0]?.category?.name : null) || "N/A"}</>)
        // },
        {
            title: appConstants?.zipcode,
            dataIndex: "zipcode",
            key: "zipcode",
            sorter: false,
            render: (title, data, index) => (<>{data.zipcode || "N/A"}</>)
        },
        {
            title: appConstants?.actions,
            dataIndex: "action",
            key: "action",
            render: (title, data, index) => {

                return (
                    <div className="table-action-button-wrapper">

                        <img className="action-icon" src={images.show} alt="" onClick={() => {

                            setCurrentUser(data)
                            setViewModal(true)
                        }} />
                        <img className="action-icon edit-icon" src={images.edit} alt="" onClick={() => {

                            // dispatch(categoryListAction({ allCategory: true, provider: data._id }))
                            setUserAction("edit")
                            setCurrentUser(data)
                            formik.resetForm()
                            setProfileImage(null)

                            setInitialValues({
                                ...formik.values,
                                name: data.name || "",
                                company: data.company || "",
                                email: data.email || "",
                                phone: data.phone || "",
                                location: data.location || [],
                            })
                            setAddModal(true)
                            setUserAction("edit")
                        }} />
                        <img className="action-icon" src={images.trash} alt="" onClick={() => {

                            setCurrentUser(data)
                            showModal()
                        }} />

                    </div>
                );
            },
        },
    ];

    const downloadCsv = () => dispatch(customerListForCsvAction({ allUser: true, type: "provider" }))

    useEffect(() => {

        dispatch(handleSearchAction({ value: "" }))
        dispatch(serviceListAction({ allService: true }))
    }, [])

    useEffect(() => {

        if (searchValue) dispatch(customerListAction({ offset: 0, limit: offsetData.limit, search: searchValue, type: "provider" }))

        else dispatch(customerListAction({ ...offsetData, search: searchValue, type: "provider" }))
    }, [offsetData, searchValue])

    useEffect(() => {

        if (userListForCsv.length) {

            const array = userListForCsv?.map(item => {
                return {
                    "Provider ID": item._id,
                    "Name": item.name || "N/A",
                    "Phone Number": item.phone || "N/A",
                    "E-mail ID": item.email || "N/A",
                    "Category": item.category || "N/A",
                    "Zip Code": item.zipcode || "N/A",
                    "Status": item.isBlock ? "Inactive" : "Active"
                }
            }).filter(Boolean)

            const csvFromArrayOfObjects = convertArrayToCSV(array);

            const blob = new Blob([csvFromArrayOfObjects], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = "Providers_list.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            dispatch(customerListForCsvRemoveAction())
        }
    }, [userListForCsv])

    useEffect(() => {

        let categories = []
        let subcategory = []

        categoryListForDropdown.map(i => {
            i.subCategory.map(x => x.selected && subcategory.push(x))

            if (i.selected) categories.push(i)
        })

        setInitialValues({
            ...formik.values,
            // category: categories.length ? categories : [],
            subCategory: subcategory.length ? subcategory : []
        })

    }, [categoryListForDropdown])

    return (
        <div className="background-wrapper">

            <div className="header">

                <div className="heading">
                    {appConstants.serviceProviders}

                    <button
                        onClick={() => {

                            formik.resetForm()
                            setProfileImage(null)
                            // setInitialValues({ name: "", company: "", email: "", phone: "", location: "", category: [], subCategory: [] })
                            setInitialValues({ name: "", company: "", email: "", phone: "", location: [], subCategory: [] })
                            setUserAction("add")
                            // dispatch(categoryListAction({ allCategory: true }))
                            setAddModal(true)

                        }}>+ {appConstants.add}</button>
                    <button onClick={downloadCsv}>{appConstants.exportCsv}</button>

                    <label htmlFor="csvUpload">
                        {appConstants.importCsv}
                    </label>

                    <input id="csvUpload" type="file" style={{ display: "none" }} accept=".csv, text/csv" onChange={(e) => {
                        if (e.target.files[0]) {

                            const formData = new FormData()
                            formData.append('csvFile', e.target.files[0])
                            dispatch(importCsvFromProviderAction(formData))
                        }
                    }} />
                </div>

                <Breadcrumbs
                    path={["dashboard", "customer-management"]}
                    pathName={[
                        <img className="breadcrumb-icon" src={images.home} alt="" />, appConstants.manage, appConstants.serviceProviders]}
                />
            </div>

            <Table

                className="table-wrapper"
                dataSource={userListData?.list?.map((i, index) => ({ ...i, key: index }))}
                columns={columns}
                showSorterTooltip={false}
                pagination={false}
                loading={loading}
            />

            <div className="pagination-wrapper">

                <div className="select-wrapper">

                    Pages :

                    <Select

                        suffixIcon={<img className="pagination-down-arrow" src={images.paginationDownIcon} alt="" />}
                        className="select"
                        defaultValue={10}
                        options={[
                            {
                                value: 10,
                                label: '10 Rows',
                            },
                            {
                                value: 20,
                                label: '20 Rows',
                            },
                            {
                                value: 30,
                                label: '30 Rows',
                            },
                        ]}
                        onChange={(e) => setOffsetData({ ...offsetData, limit: e })}
                    />
                </div>


                <Pagination

                    className="pagination"
                    current={currentPage}
                    total={userListData?.pagination?.totalCount}
                    showLessItems={false}
                    onChange={(e) => {

                        setCurrentPage(e)
                        setOffsetData({ ...offsetData, offset: (e * offsetData.limit) - offsetData.limit })
                    }}

                    pageSize={offsetData.limit}
                />
                <div className="action-wrapper" >

                    <span className={prevOffset >= 0 ? "action" : "action cursor-not-allowed"} onClick={() => {



                        if (prevOffset >= 0) {
                            setOffsetData({ ...offsetData, offset: prevOffset })
                            setCurrentPage(currentPage - 1)
                        }
                    }}>

                        <ArrowLeftOutlined className="left-icon" />
                        {appConstants.previous}
                    </span>

                    <span className={nextOffset < (userListData?.pagination?.totalCount || 0) ? "action" : "action cursor-not-allowed"} onClick={() => {


                        if (nextOffset < (userListData?.pagination?.totalCount || 0)) {
                            setOffsetData({ ...offsetData, offset: nextOffset })
                            setCurrentPage(currentPage + 1)
                        }
                    }}>

                        {appConstants.next}
                        <ArrowRightOutlined className="right-icon" />

                    </span>
                </div>
            </div>


            <Modal

                className="logout-modal"
                title={
                    <div className="heading">
                        <DeleteOutlined />

                        {appConstants.delete}

                    </div>
                }
                centered={true}
                closable={false}
                open={modalVisible}
                onCancel={hideModal}
                footer={[
                    <button key="success" className="footer-button ok-btn" onClick={() => {

                        hideModal()
                        setViewModal(false)
                        dispatch(customerDeleteAction({ payload: { userId: currentUser?._id, type: "provider" }, list: { ...offsetData, search: searchValue, type: "provider" } }))

                    }}>{appConstants.yes}</button>,
                    <button key="failed" className="footer-button cancel-btn" onClick={() => {
                        hideModal()
                    }}>{appConstants.no}</button>

                ]}
            >
                {appConstants.deleteProviderWarning}
            </Modal>

            <Modal

                className="customer-modal"
                centered={true}
                closable={false}
                open={showViewModal}
                footer={false}
                onCancel={() => setViewModal(false)}
            >
                <div className="header">

                    <div className="heading">
                        {appConstants.providerPersonalDetails}
                    </div>

                </div>

                <div className="personal-detail-wrapper">

                    <div className="detail-wrapper">

                        <div className="field-wrapper">

                            <div className="field">{appConstants.name}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentUser?.name || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.phoneNumber}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentUser?.phone || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.emailId}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentUser?.email || "N/A"}</div>
                        </div>
                        <div className="field-wrapper">

                            <div className="field">{appConstants.location}</div>
                            <div className="value">:  <span>&nbsp;</span> {currentUser?.location[0]?.city || "N/A"}</div>
                        </div>

                    </div>

                    <div className="action-wrapper">

                        <img className="icon" src={images.trash} alt="" onClick={() => showModal()} />

                    </div>

                </div>

                <div className="header">

                    <div className="heading">
                        {appConstants.serviceOffered}
                    </div>
                </div>

                <div className="customer-service-wrapper">

                    {
                        currentUser?.providerService?.map((item, index) => {

                            if (item?.service?.name) return <div className="service" key={index}>{item?.service?.name}</div>
                        })
                    }
                </div>


                <div className="header">

                    <div className="heading">
                        {appConstants.activities}
                    </div>
                </div>


                <div className="personal-detail-wrapper">

                    <div className="detail-wrapper">

                        <div className="field-wrapper">

                            <div className="field">{appConstants.totalRating}</div>
                            <div className="value">:  &nbsp; {currentUser?.totalRating} <Rate disabled={true} className="rate" allowHalf value={currentUser?.totalRating || 0} /></div>
                        </div>

                        <div className="field-wrapper">

                            <div className="field">{appConstants.totalReviews}</div>
                            <div className="value">:  &nbsp; {currentUser?.totalReviews} Reviews</div>
                        </div>

                    </div>

                </div>

            </Modal>

            <Modal

                className="customer-modal"
                centered={true}
                closable={false}
                open={showAddModal}
                footer={false}
                onCancel={() => {
                    formik.resetForm()
                    setAddModal(false)
                }}
            >
                <form className="add-provider-wrapper" onSubmit={formik.handleSubmit}>

                    <label className="profile-image-wrapper" htmlFor="image-upload">

                        <img src={images.addImageIcon} className="add-image-icon" alt="" />
                        <img src={profileImage ? URL.createObjectURL(profileImage) : currentUser?.image ? appConstants.imageUrl + currentUser?.image : images.dummyUser} className="image" alt="" />

                        <input accept="image/*" type="file" id="image-upload" style={{ display: "none" }} onChange={(e) => e.target.files[0] && setProfileImage(e.target.files[0])} />

                    </label>

                    <div className="fields-wrapper fields-wrapper-row">
                        <div className="left-content">
                            <label>{appConstants.name} <span>*</span></label>
                            <input
                                placeholder={appConstants.name}
                                maxLength={30}
                                name="name"
                                value={formik.values.name}
                                onChange={(e) => {

                                    if (e.target.value.startsWith(" ")) e.preventDefault()
                                    else formik.handleChange(e)
                                }}
                            />

                            {formik.errors.name && <div className="validation">{formik.errors.name}</div>}
                        </div>

                        <div className="right-content">
                            <label>{appConstants.company} <span>*</span></label>
                            <input
                                placeholder={appConstants.company}
                                maxLength={30}
                                name="company"
                                value={formik.values.company}
                                onChange={(e) => {

                                    if (e.target.value.startsWith(" ")) e.preventDefault()
                                    else formik.handleChange(e)
                                }}
                            />
                            {formik.errors.company && <div className="validation">{formik.errors.company}</div>}

                        </div>
                    </div>

                    <div className="fields-wrapper fields-wrapper-row">
                        <div className="left-content">
                            <label>{appConstants.emailid} <span>*</span></label>
                            <input
                                placeholder={appConstants.email}
                                name="email"
                                value={formik.values.email}
                                onChange={(e) => {

                                    if (e.target.value.startsWith(" ")) e.preventDefault()
                                    else formik.handleChange(e)
                                }}
                            />
                            {formik.errors.email && <div className="validation">{formik.errors.email}</div>}

                        </div>

                        <div className="right-content">
                            <label>{appConstants.phoneNo} <span>*</span></label>
                            <input
                                placeholder={appConstants.number}
                                maxLength={15}
                                name="phone"
                                value={formik.values.phone}
                                onChange={(e) => {

                                    if (/^[0-9]+$/.test(e.target.value) || !e.target.value) formik.handleChange(e)
                                    else e.preventDefault()
                                }}
                            />
                            {formik.errors.phone && <div className="validation">{formik.errors.phone}</div>}

                        </div>
                    </div>

                    <div className="fields-wrapper">
                        <label>{appConstants.location} <span>*</span></label>
                        {/* <input
                            placeholder={appConstants.enterLocation}
                            name="location"
                            value={formik.values.location}
                            onChange={(e) => {

                                if (e.target.value.startsWith(" ")) e.preventDefault()
                                else formik.handleChange(e)
                            }}
                            maxLength={40}
                        /> */}

                        <PlacesAutocomplete
                            className="auto-complete"
                            value={locationInputValue}
                            // disabled={(currentItem >= 0)}
                            onChange={handleAutoCompleteChange}
                            onSelect={handleAutoCompleteSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <div className="location-search-container">

                                        {initialValues.location?.length ? initialValues?.location?.map((item, index) => (
                                            <span
                                                key={index}
                                                className="location-search-selected"
                                            >
                                                {item?.city}
                                                <CloseOutlined onClick={() => setInitialValues(x => {

                                                    delete x.location[index]
                                                    x.location = x.location.filter(Boolean)

                                                    return { ...formik.values, location: x.location };
                                                })} />
                                            </span>
                                        )) : ""}

                                        <input
                                            maxLength={30}

                                            {...getInputProps({
                                                placeholder: initialValues.location.length ? "" : appConstants.location,
                                                className: 'location-search-input',
                                            })}
                                        // disabled={(currentItem >= 0)}

                                        />

                                    </div>
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                            )}


                        </PlacesAutocomplete>

                        {formik.errors.location && <div className="validation">{formik.errors.location}</div>}

                    </div>

                    {/* <div className="fields-wrapper">
                        <label>{appConstants.serviceCategory} <span>*</span></label>
                        <Select

                            className="select"
                            mode="multiple"
                            placeholder={appConstants.pickCategory}
                            options={categoryListForDropdown}
                            name="category"
                            value={formik.values.category}
                            onChange={(values, items) => {
                                let newSubcategories = []

                                items.forEach(i => newSubcategories.push(...i.subCategory))

                                let newSelectedSubcategoryArray = newSubcategories.filter(x => formik.values.subCategory.map(i => i.value).includes(x.value))

                                setSubCategoryList(newSubcategories)
                                setInitialValues({ ...formik.values, category: items, subCategory: newSelectedSubcategoryArray })
                            }}

                        />
                        <div className="validation height-10">{formik.errors.category}</div>

                    </div> */}

                    <div className="fields-wrapper">
                        <label>{appConstants.services} <span>*</span></label>

                        <Select

                            className="select sub-category-select"
                            placeholder={appConstants.pickSubCategory}
                            mode="multiple"
                            options={serviceListData?.list?.map(i => ({ label: i.name, value: i._id }))}
                            name="subCategory"
                            value={formik.values.subCategory}
                            onChange={(values, items) => {
                                setInitialValues({ ...formik.values, subCategory: items })

                            }}
                        />
                        <div className="validation height-10">{formik.errors.subCategory}</div>

                    </div>

                    <div className="btn-wrapper"><button type="submit">{appConstants.submit}</button></div>
                </form>

            </Modal>
        </div>
    )
}

export default ServiceProviderManagement;